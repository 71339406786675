import React from 'react';



const Shapka1 = () => {
    return (
        <div className="center-photo-header">
            <div className="bg"></div>
            <div className="bg bg2"></div>
            <div className="bg bg3"></div>
            <div className="content content__header">
                <div className="h-list-glass">Стекло для бизнеса</div>
            </div>
        </div>

    );
}
    export default Shapka1;