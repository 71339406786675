import React from 'react';
import WorksGallery1 from './Components/WorksGallery/WorksGallery1';
import Footer from './Components/Identification/Footer';
import FooterUpdate from "./Components/Identification/FooterUpdate";

const WorksGallery = () => (
  <>
    <WorksGallery1 />
    <FooterUpdate />
</>
);

export default WorksGallery;