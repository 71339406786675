import React from 'react';
import icon1900239 from '../../assets/img/icons/free-icon-tip-1900239.png'
import icon2 from '../../assets/img/icons/free-icon-solutions-2349112.png'
import icon3 from '../../assets/img/icons/free-icon-calculating-1739422.png'
import '../../styles/adv.css';

const Advantages = () => {
    return (
        <section id="advantages" className="advantages">
            <ul className="tilesWrap">
                <li>
                    <h2>01</h2>
                    <h3>Готовые решения</h3>
                    <p>
                        Мы предлагаем широкий ассортимент готовых
                        стеклянных конструкций, включая окна, двери,
                        перегородки и многие другие изделия. Это позволяет
                        нашим клиентам выбирать из множества стилей, форм и
                        материалов.
                    </p>
                </li>
                <li>
                    <h2>02</h2>
                    <h3>Индивидуальный подход</h3>
                    <p>
                        Мы специализируемся на создании индивидуальных
                        стеклянных решений, предлагаем индивидуальное проектирование и изготовление
                        стеклянных конструкций с учетом требований и предпочтений клиента.
                    </p>
                </li>
                <li>
                    <h2>03</h2>
                    <h3>Расчет по вашему проекту</h3>
                    <p>
                        Мы всегда готовы предоставить точный расчет стоимости работ и материалов для каждого проекта.
                        Это обеспечивает прозрачность и позволяет клиентам планировать свой бюджет, не беспокоясь о
                        неожиданных расходах
                    </p>
                </li>
                <li>
                    <h2>04</h2>
                    <h3>Гарантия уникальности</h3>
                    <p>
                        Мы предоставляем гарантию на уникальность каждого проекта, который мы разрабатываем и реализуем.
                    </p>
                </li>
                <li>
                    <h2>05</h2>
                    <h3>Высокое качество и прочность</h3>
                    <p>
                        Компания использует только высококачественные материалы и современные технологии производства,
                        чтобы обеспечить продукты высокой прочности и долговечности.
                    </p>
                </li>
            </ul>
        </section>
    );
};

export default Advantages;